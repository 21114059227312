import { Grid, Switch } from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import { apiBase } from 'api/base/base.api'
import { Notification } from 'components/Notification'
import { useNotification } from 'hooks/useNotification'
import { reference } from 'modules'


import { AisSettingFormFields, AisSettingFormProps } from '../AisSettingsPage.props'

import styles from './styles.module.css'

export const AisSettingsForm = ({ onSubmit }:AisSettingFormProps) => {
  const [mode, setMode] = useState<'show' | 'edit'>('show')
  const dispatch = useDispatch()

  const { openNotification, notificationData } = useNotification()

  const updateDataAsorLoading = useSelector(reference.useGetAsorUpdateByDateLoading)
  const updateDataAsorSuccess = useSelector(reference.useGetAsorUpdateByDateSuccess)
  const updateDataAsorErrorMessage = useSelector(reference.useGetAsorUpdateByDateErrorMessage)

  
  const updateDataAllLoading = useSelector(reference.useUpdateDataLoading)
  const updateDataAllSuccess = useSelector(reference.useUpdateDataSuccess)
  const updateDataAllErrorMessage = useSelector(reference.useUpdateDataErrorMessage)



  const toggleModeHandler = () =>
    setMode((currState) => (currState === 'show' ? 'edit' : 'show'))

  const {
    formState: { defaultValues, isDirty, isValid },
    watch,
    setValue,
    register,
    handleSubmit,
  } = useForm<AisSettingFormFields>({
    defaultValues: {
      authSwitch: true,
      egrDbSwitch: true,
      egrApiSwitch: false,
      acopDbSwitch: true,
      acopApiSwitch: false,
      grpDbSwitch: true,
      grpApiSwitch: false,
      dateACOP: '2024-01-01'
    },
    mode: 'all',
  })

  const saveShangesHandler: SubmitHandler<AisSettingFormFields>  = (formValues) => {
    onSubmit()
    toggleModeHandler()
  }

  const changeStatusHandler = (e: ChangeEvent<HTMLInputElement>, currName: keyof AisSettingFormFields, oppositeName: keyof AisSettingFormFields) => {
    setValue(currName, e.target.checked)
    setValue(oppositeName, !e.target.checked)
  }

  // egr
  const getEgrDataHandler = () => {}

  // acop   

  const getAcopDataHandler = () => {
    //@ts-ignore
    //await apiBase.get(`reference-organizations/asorByDate/${watch('dateACOP')}`)
    //await apiBase.get('reference-organizations/updateData')
    dispatch(reference.actions.getAsorByDate(watch('dateACOP')))

  }
  useEffect(() => {
    if(updateDataAsorSuccess){
      openNotification('Данные успешно загружены', 'success')
      dispatch(reference.actions.updateData())
    }
    if(updateDataAsorErrorMessage){
      openNotification(updateDataAsorErrorMessage, 'error')
    }

  },[updateDataAsorLoading, updateDataAsorSuccess, updateDataAsorErrorMessage, openNotification, dispatch])

  useEffect(() => {
    if(updateDataAllSuccess){
      openNotification('Обновление запущено', 'success')
    }
    if(updateDataAllErrorMessage){
      openNotification(updateDataAllErrorMessage, 'error')
    }
  }, [updateDataAllSuccess, updateDataAllErrorMessage, openNotification])

  // grp 
  const getGrpDataHandler = () => {}

  return (
    <>
      <form className={styles.form} onSubmit={handleSubmit(saveShangesHandler)}>
        <div className={styles.form__block}>
          <div className={styles.form__blockHeadlineWr}>
            <h2 className={styles.form__blockHeadline}>Авторизация</h2>
          </div>
          <div className={styles.form__switcherBlock}>
            <Switch
              className={styles.form__switcher}
              id='authSwitch'
              edge='start'
              defaultChecked={defaultValues?.authSwitch}
              disabled={mode === 'show'}
              {...register('authSwitch')}
            />
            <label htmlFor='authSwitch' className={styles.form__switcherLabel}>
            Авторизация с помощью ЕС ИФЮЛ
            </label>
          </div>
        </div>
        <div className={styles.form__block}>
          <div className={styles.form__blockHeadlineWr}>
            <h2 className={styles.form__blockHeadline}>ЕГР</h2>
          </div>
          <div className={styles.form__switcherBlock}>
            <Grid container spacing={2} columnSpacing='242px'>
              <Grid item sm={6}>
                <Grid container spacing={2} flexDirection='column'>
                  <Grid item sm={12}>
                    <Switch
                      className={styles.form__switcher}
                      id='egrDbSwitch'
                      edge='start'
                      checked={watch('egrDbSwitch') === undefined ? defaultValues?.egrDbSwitch : watch('egrDbSwitch')}
                      disabled={mode === 'show'}
                      {...register('egrDbSwitch')}
                      onChange={(e) => changeStatusHandler(e, 'egrDbSwitch','egrApiSwitch')}
                    />
                    <label htmlFor='egrDbSwitch' className={styles.form__switcherLabel}>
                    dbLink
                    </label>
                  </Grid>
                  <Grid item sm={12}>
                    <div className={styles.form__inputBlock}>
                      <label htmlFor='' className={styles.form__inputLabel}>
                      IP адрес
                      </label>
                      <input
                        className={styles.form__input}
                        type='text'
                        placeholder='IP адрес для dbLink'
                        disabled={mode === 'show' || !(watch('egrDbSwitch') === undefined ? defaultValues?.egrDbSwitch : watch('egrDbSwitch'))}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={12}>
                    <div className={styles.form__inputBlock}>
                      <label htmlFor='' className={styles.form__inputLabel}>
                      Название БД
                      </label>
                      <input
                        className={styles.form__input}
                        type='text'
                        placeholder='Наименование БД для dbLink'
                        disabled={mode === 'show' || !(watch('egrDbSwitch') === undefined ? defaultValues?.egrDbSwitch : watch('egrDbSwitch'))}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={12}>
                    <div className={styles.form__inputBlock}>
                      <label htmlFor='' className={styles.form__inputLabel}>
                      Логин
                      </label>
                      <input
                        className={styles.form__input}
                        type='text'
                        placeholder='Логин для подключения  БД'
                        disabled={mode === 'show' || !(watch('egrDbSwitch') === undefined ? defaultValues?.egrDbSwitch : watch('egrDbSwitch'))}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={12}>
                    <div className={styles.form__inputBlock}>
                      <label htmlFor='' className={styles.form__inputLabel}>
                      Пароль
                      </label>
                      <input
                        className={styles.form__input}
                        type='text'
                        placeholder='Пароль для подключения  БД'
                        disabled={mode === 'show' || !(watch('egrDbSwitch') === undefined ? defaultValues?.egrDbSwitch : watch('egrDbSwitch'))}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={6}>
                <Grid container spacing={2} flexDirection='column'>
                  <Grid item sm={12}>
                    <Switch
                      className={styles.form__switcher}
                      id='egrApiSwitch'
                      edge='start'
                      checked={watch('egrApiSwitch') === undefined ? defaultValues?.egrApiSwitch : watch('egrApiSwitch')}
                      disabled={mode === 'show'}
                      {...register('egrApiSwitch')}
                      onChange={(e) => changeStatusHandler(e, 'egrApiSwitch','egrDbSwitch')}
                    />
                    <label htmlFor='egrApiSwitch' className={styles.form__switcherLabel}>
                    API ОАИС
                    </label>
                  </Grid>
                  <Grid item sm={12}>
                    <div className={styles.form__inputBlock}>
                      <label htmlFor='' className={styles.form__inputLabel}>
                      URL
                      </label>
                      <input
                        className={styles.form__input}
                        type='text'
                        placeholder='Адрес API для API ОАИС'
                        disabled={mode === 'show' || !(watch('egrApiSwitch') === undefined ? defaultValues?.egrApiSwitch : watch('egrApiSwitch'))}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={12}>
                    <div className={styles.form__inputBlock}>
                      <label htmlFor='' className={styles.form__inputLabel}>
                      Токен доступа к ОАИС
                      </label>
                      <input
                        className={styles.form__input}
                        type='text'
                        placeholder='Токен доступа для API ОАИС'
                        disabled={mode === 'show' || !(watch('egrApiSwitch') === undefined ? defaultValues?.egrApiSwitch : watch('egrApiSwitch'))}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <button className={styles.form__blockBtn} type='submit' onClick={getEgrDataHandler}>
          Получить данные ЕГР
          </button>
        </div>
        <div className={styles.form__block}>
          <div className={styles.form__blockHeadlineWr}>
            <h2 className={styles.form__blockHeadline}>ACOP</h2>
          </div>
          <div className={styles.form__switcherBlock}>
            <Grid container spacing='16px' columnSpacing='242px'>
              <Grid item sm={6}>
                <Grid container spacing={2} flexDirection='column'>
                  <Grid item sm={12}>
                    <Switch
                      className={styles.form__switcher}
                      id='acopDbSwitch'
                      edge='start'
                      checked={watch('acopDbSwitch') === undefined ? defaultValues?.acopDbSwitch : watch('acopDbSwitch')}
                      disabled={mode === 'show'}
                      {...register('acopDbSwitch')}
                      onChange={(e) => changeStatusHandler(e, 'acopDbSwitch','acopApiSwitch')}
                    />
                    <label htmlFor='acopDbSwitch' className={styles.form__switcherLabel}>
                    JSON
                    </label>
                  </Grid>
                  <Grid item sm={12}>
                    <div className={styles.form__inputBlock}>
                      <label htmlFor='' className={styles.form__inputLabel}>
                      Путь
                      </label>
                      <input
                        className={styles.form__input}
                        type='text'
                        placeholder='Путь к файлу JSON'
                        disabled={mode === 'show' || !(watch('acopDbSwitch') === undefined ? defaultValues?.acopDbSwitch : watch('acopDbSwitch'))}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={12}>
                    <div className={styles.form__inputBlock}>
                      <label htmlFor='' className={styles.form__inputLabel}>
                      Маска файла с организациями
                      </label>
                      <input
                        className={styles.form__input}
                        type='text'
                        placeholder='Маска файла  JSON'
                        disabled={mode === 'show' || !(watch('acopDbSwitch') === undefined ? defaultValues?.acopDbSwitch : watch('acopDbSwitch'))}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={6}>
                <Grid container spacing={2} flexDirection='column'>
                  <Grid item sm={12}>
                    <Switch
                      className={styles.form__switcher}
                      id='acopApiSwitch'
                      edge='start'
                      checked={watch('acopApiSwitch') === undefined ? defaultValues?.acopApiSwitch : watch('acopApiSwitch')}
                      disabled={mode === 'show'}
                      {...register('acopApiSwitch')}
                      onChange={(e) => changeStatusHandler(e, 'acopApiSwitch','acopDbSwitch')}
                    />
                    <label htmlFor='acopApiSwitch' className={styles.form__switcherLabel}>
                    API ОАИС
                    </label>
                  </Grid>
                  <Grid item sm={12}>
                    <div className={styles.form__inputBlock}>
                      <label
                        htmlFor={styles.form__inputLabel}
                        className={styles.form__inputLabel}
                      >
                      URL
                      </label>
                      <input
                        className={styles.form__input}
                        type='text'
                        placeholder='https://dcb-dev.sws.by/api/dcb-oais/v1/state-owner/date/:dateModify'
                        disabled
                      />
                    </div>
                  </Grid>
                  <Grid item sm={12}>
                    <div className={styles.form__inputBlock}>
                      <label
                        htmlFor={styles.form__inputLabel}
                        className={styles.form__inputLabel}
                      >
                      Дата обновления(YYYY-MM-DD)
                      </label>
                      <input
                        className={styles.form__input}
                        type='text'
                        {...register('dateACOP')}
                        placeholder='Токен доступа для API ОАИС'
                        disabled={mode === 'show' || !(watch('acopApiSwitch') === undefined ? defaultValues?.acopApiSwitch : watch('acopApiSwitch'))}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <button className={styles.form__blockBtn} type='button' onClick={getAcopDataHandler} disabled={updateDataAsorLoading || updateDataAllLoading}>
          Получить данные ACOP
          </button>
        </div>
        <div className={styles.form__block}>
          <div className={styles.form__blockHeadlineWr}>
            <h2 className={styles.form__blockHeadline}>ГРП</h2>
          </div>
          <div className={styles.form__switcherBlock}>
            <Grid container spacing={2} columnSpacing='242px'>
              <Grid item sm={6}>
                <Grid container spacing={2} flexDirection='column'>
                  <Grid item sm={12}>
                    <Switch
                      className={styles.form__switcher}
                      id='grpDbSwitch'
                      edge='start'
                      checked={watch('grpDbSwitch') === undefined ? defaultValues?.grpDbSwitch : watch('grpDbSwitch')}
                      disabled={mode === 'show'}
                      {...register('grpDbSwitch')}
                      onChange={(e) => changeStatusHandler(e, 'grpDbSwitch','grpApiSwitch')}
                    />
                    <label htmlFor='grpDbSwitch' className={styles.form__switcherLabel}>
                    CSV
                    </label>
                  </Grid>
                  <Grid item sm={12}>
                    <div className={styles.form__inputBlock}>
                      <label htmlFor='' className={styles.form__inputLabel}>
                      Путь
                      </label>
                      <input
                        className={styles.form__input}
                        type='text'
                        placeholder='Путь к файлу CSV'
                        disabled={mode === 'show' || !(watch('grpDbSwitch') === undefined ? defaultValues?.grpDbSwitch : watch('grpDbSwitch'))}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={12}>
                    <div className={styles.form__inputBlock}>
                      <label htmlFor='' className={styles.form__inputLabel}>
                      Маска файла с организациями
                      </label>
                      <input
                        className={styles.form__input}
                        type='text'
                        placeholder='Маска файла  CSV'
                        disabled={mode === 'show' || !(watch('grpDbSwitch') === undefined ? defaultValues?.grpDbSwitch : watch('grpDbSwitch'))}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={6}>
                <Grid container spacing={2} flexDirection='column'>
                  <Grid item sm={12}>
                    <Switch
                      className={styles.form__switcher}
                      edge='start'
                    
                      id='grpApiSwitch'
                      checked={watch('grpApiSwitch') === undefined ? defaultValues?.grpApiSwitch : watch('grpApiSwitch')}
                      disabled={mode === 'show'}
                      {...register('grpApiSwitch')}
                      onChange={(e) => changeStatusHandler(e, 'grpApiSwitch','grpDbSwitch')}
                    />
                    <label htmlFor='grpApiSwitch' className={styles.form__switcherLabel}>
                    API ОАИС
                    </label>
                  </Grid>
                  <Grid item sm={12}>
                    <div className={styles.form__inputBlock}>
                      <label htmlFor='' className={styles.form__inputLabel}>
                      URL
                      </label>
                      <input
                        className={styles.form__input}
                        type='text'
                        placeholder='Адрес API для API ОАИС'
                        disabled={mode === 'show' || !(watch('grpApiSwitch') === undefined ? defaultValues?.grpApiSwitch : watch('grpApiSwitch'))}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={12}>
                    <div className={styles.form__inputBlock}>
                      <label htmlFor='' className={styles.form__inputLabel}>
                      Токен доступа к ОАИС
                      </label>
                      <input
                        className={styles.form__input}
                        type='text'
                        placeholder='Токен доступа для API ОАИС'
                        disabled={mode === 'show' || !(watch('grpApiSwitch') === undefined ? defaultValues?.grpApiSwitch : watch('grpApiSwitch'))}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <button className={styles.form__blockBtn} type='button' onClick={getGrpDataHandler}>
          Получить данные ГРП
          </button>
        </div>
        <div className={styles.form__blockInfo}>
          <h3 className={styles.form__subhealine}>Период получения данныx</h3>
          <span className={styles.form__time}>50 минут</span>
        </div>
        {mode === 'show' ? (
          <button
            className={styles.form__btn}
            type='button'
            onClick={toggleModeHandler}
          >
          Редактировать
          </button>
        ) : (
          <div className={styles.form__btnsGroup}>
            <button
              className={styles.form__btn}
              disabled={!isDirty || !isValid}
              type='submit'
            >
            Сохранить
            </button>
            <button
              className={styles.form__btn}
              type='button'
              onClick={toggleModeHandler}
            >
            Отмена
            </button>
          </div>
        )}
      </form>
      <Notification {...notificationData} />
    </>
  )
}
