import {
  useMetaIdleStatus,
  useMetaLoadingStatus,
  useMetaSuccessStatus,
  useMetaErrorStatus,
  useMetaErrorMessage,
  useDataCurrPage,
  useDataLastPage,
  useDataItemsTotalCount,
  useDataItems,
} from 'modules/base'

import { ReferenceSlice } from './reference.schema'

export const useListFilter = ({ reference }: ReferenceSlice) =>
  reference.listFilter

export const useListIdle = ({ reference }: ReferenceSlice) =>
  useMetaIdleStatus(reference.list)

export const useListLoading = ({ reference }: ReferenceSlice) =>
  useMetaLoadingStatus(reference.list)

export const useListSuccess = ({ reference }: ReferenceSlice) =>
  useMetaSuccessStatus(reference.list)

export const useListError = ({ reference }: ReferenceSlice) =>
  useMetaErrorStatus(reference.list)

export const useListErrorMessage = ({ reference }: ReferenceSlice) =>
  useMetaErrorMessage(reference.list)

export const useListCurrPage = ({ reference }: ReferenceSlice) =>
  useDataCurrPage(reference.list)

export const useListLastPage = ({ reference }: ReferenceSlice) =>
  useDataLastPage(reference.list)

export const useListItemsTotalCount = ({ reference }: ReferenceSlice) =>
  useDataItemsTotalCount(reference.list)

export const useListItems = ({ reference }: ReferenceSlice) =>
  useDataItems(reference.list)

export const useCreationIdle = ({ reference }: ReferenceSlice) =>
  useMetaIdleStatus(reference.creation)

export const useCreationLoading = ({ reference }: ReferenceSlice) =>
  useMetaLoadingStatus(reference.creation)

export const useCreationSuccess = ({ reference }: ReferenceSlice) =>
  useMetaSuccessStatus(reference.creation)

export const useCreationError = ({ reference }: ReferenceSlice) =>
  useMetaErrorStatus(reference.creation)

export const useCreationErrorMessage = ({ reference }: ReferenceSlice) =>
  useMetaErrorMessage(reference.creation)

export const useCreationData = ({ reference }: ReferenceSlice) =>
  reference.creation.data

export const useUpdationIdle = ({ reference }: ReferenceSlice) =>
  useMetaIdleStatus(reference.updation)

export const useUpdationLoading = ({ reference }: ReferenceSlice) =>
  useMetaLoadingStatus(reference.updation)

export const useUpdationSuccess = ({ reference }: ReferenceSlice) =>
  useMetaSuccessStatus(reference.updation)

export const useUpdationError = ({ reference }: ReferenceSlice) =>
  useMetaErrorStatus(reference.updation)

export const useUpdationErrorMessage = ({ reference }: ReferenceSlice) =>
  useMetaErrorMessage(reference.updation)

export const useUpdationData = ({ reference }: ReferenceSlice) =>
  reference.updation.data

export const useDeletionIdle = ({ reference }: ReferenceSlice) =>
  useMetaIdleStatus(reference.deletion)

export const useDeletionLoading = ({ reference }: ReferenceSlice) =>
  useMetaLoadingStatus(reference.deletion)

export const useDeletionSuccess = ({ reference }: ReferenceSlice) =>
  useMetaSuccessStatus(reference.deletion)

export const useDeletionError = ({ reference }: ReferenceSlice) =>
  useMetaErrorStatus(reference.deletion)

export const useDeletionErrorMessage = ({ reference }: ReferenceSlice) =>
  useMetaErrorMessage(reference.deletion)

export const useDeletionData = ({ reference }: ReferenceSlice) =>
  reference.deletion.data

export const useImportionIdle = ({ reference }: ReferenceSlice) =>
  useMetaIdleStatus(reference.importion)

export const useImportionLoading = ({ reference }: ReferenceSlice) =>
  useMetaLoadingStatus(reference.importion)

export const useImportionSuccess = ({ reference }: ReferenceSlice) =>
  useMetaSuccessStatus(reference.importion)

export const useImportionError = ({ reference }: ReferenceSlice) =>
  useMetaErrorStatus(reference.importion)

export const useImportionErrorMessage = ({ reference }: ReferenceSlice) =>
  useMetaErrorMessage(reference.importion)

export const useGetAsorUpdateByDateLoading = ({ reference }: ReferenceSlice) => 
  useMetaLoadingStatus(reference.updateData)

export const useGetAsorUpdateByDateSuccess = ({ reference }: ReferenceSlice) => 
  useMetaSuccessStatus(reference.updateData)

export const useGetAsorUpdateByDateErrorMessage = ({ reference }: ReferenceSlice) => 
  useMetaErrorMessage(reference.updateData)

export const useUpdateDataLoading = ({ reference }: ReferenceSlice) => 
  useMetaLoadingStatus(reference.updateDataAll)

export const useUpdateDataSuccess = ({ reference }: ReferenceSlice) => 
  useMetaSuccessStatus(reference.updateDataAll)

export const useUpdateDataErrorMessage = ({ reference }: ReferenceSlice) => 
  useMetaErrorMessage(reference.updateDataAll)
