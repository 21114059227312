import { AxiosError } from 'axios'
import { spawn, takeEvery, call, put, select } from 'redux-saga/effects'

import {
  Base,
  ReferenceList,
  ReferenceCreate,
  ReferenceUpdate,
  ReferenceRemove,
  ReferenceImportSource,
  reference,
} from 'api'

import {
  UpdateListFilter,
  Create,
  Update,
  Delete,
  Import,
  GetAsorByDate,
  UpdateData
} from './reference.action'
import * as creator from './reference.creator'
import * as selector from './reference.selector'

function* updateAsorByDateWorker(action: GetAsorByDate) {
  let errorMessage = undefined

  try {
    const {
      data: { data, meta, errorCode },
    }: ReferenceList.Response = yield call(reference.asorByDate, action.payload)
    if (data) {
      yield put(creator.getAsorByDateSuccess())
    } 
    else if (errorCode && meta) {
      errorMessage = meta
    }
  } catch (exception) {
    if (exception instanceof AxiosError) {
      errorMessage = exception.response?.data.meta 
    } else {
      errorMessage = Base.ErrorMessage.UNKNOWN
    }
  } finally {
    if (errorMessage) {
      yield put(creator.getAsorByDateError(errorMessage))
    }
  }
}

function* updateAsorByDateWatcher() {
  yield takeEvery<GetAsorByDate>('reference.asor_by_date' as GetAsorByDate['type'], updateAsorByDateWorker)
}

function* updateDataWorker(action: UpdateData) {
  let errorMessage = undefined

  try {
    const {
      data: { data, meta, errorCode },
    }: ReferenceList.Response = yield call(reference.updateData)
    if (data) {
      yield put(creator.updateDataSuccess())
    } 
    else if (errorCode && meta) {
      errorMessage = meta
    }
  } catch (exception) {
    if (exception instanceof AxiosError) {
      errorMessage = exception.response?.data.meta 
    } else {
      errorMessage = Base.ErrorMessage.UNKNOWN
    }
  } finally {
    if (errorMessage) {
      yield put(creator.updateDataError(errorMessage))
    }
  }
}

function* updateDataWatcher() {
  yield takeEvery<UpdateData>('reference.update_data' as UpdateData['type'], updateDataWorker)
}

function* updateListFilterWorker() {
  let errorMessage = undefined

  try {
    const filter: ReturnType<typeof selector.useListFilter> = yield select(
      selector.useListFilter
    )

    yield put(creator.getList())

    const {
      data: { data, meta, errorCode },
    }: ReferenceList.Response = yield call(reference.list, filter)

    if (data) {
      yield put(creator.getListSuccess(data))
    } else if (errorCode && meta) {
      errorMessage = meta
    }
  } catch (exception) {
    if (exception instanceof AxiosError) {
      errorMessage = exception.response?.data.meta 
    } else {
      errorMessage = Base.ErrorMessage.UNKNOWN
    }
  } finally {
    if (errorMessage) {
      yield put(creator.getListError(errorMessage))
    }
  }
}

function* updateListFilterWatcher() {
  yield takeEvery<UpdateListFilter>(
    'reference.update_list_filter' as UpdateListFilter['type'],
    updateListFilterWorker
  )
}

function* createWorker(action: Create) {
  let errorMessage = undefined

  try {
    const {
      data: { data, meta, errorCode },
    }: ReferenceCreate.Response = yield call(reference.create, action.payload)

    if (data) {
      yield put(creator.createSuccess(data))
    } else if (errorCode && meta) {
      errorMessage = meta
    }
  } catch (exception) {
    if (exception instanceof AxiosError) {
      errorMessage = exception.response?.data.meta 
    } else {
      errorMessage = Base.ErrorMessage.UNKNOWN
    }
  } finally {
    if (errorMessage) {
      yield put(creator.createError(errorMessage))
    }
  }
}

function* createWatcher() {
  yield takeEvery<Create>('reference.create' as Create['type'], createWorker)
}

function* updateWorker(action: Update) {
  let errorMessage = undefined

  try {
    const {
      data: { data, meta, errorCode },
    }: ReferenceUpdate.Response = yield call(reference.update, action.payload)

    if (data) {
      yield put(creator.updateSuccess(data))
    } else if (errorCode && meta) {
      errorMessage = meta
    }
  } catch (exception) {
    if (exception instanceof AxiosError) {
      errorMessage = exception.response?.data.meta 
    } else {
      errorMessage = Base.ErrorMessage.UNKNOWN
    }
  } finally {
    if (errorMessage) {
      yield put(creator.updateError(errorMessage))
    }
  }
}

function* updateWatcher() {
  yield takeEvery<Update>('reference.update' as Update['type'], updateWorker)
}

function* deleteWorker(action: Delete) {
  let errorMessage = undefined

  try {
    const {
      data: { data, meta, errorCode },
    }: ReferenceRemove.Response = yield call(reference.remove, action.payload)

    if (!data) {
      yield put(creator.removeSuccess())
    } else if (errorCode && meta) {
      errorMessage = meta
    }
  } catch (exception) {
    if (exception instanceof AxiosError) {
      errorMessage = exception.response?.data.meta 
    } else {
      errorMessage = Base.ErrorMessage.UNKNOWN
    }
  }finally {
    if (errorMessage) {
      yield put(creator.removeError(errorMessage))
    }
  }
}

function* deleteWatcher() {
  yield takeEvery<Delete>('reference.delete' as Delete['type'], deleteWorker)
}

function* importWorker(action: Import) {
  let errorMessage = undefined

  try {
    const {
      data: { data, meta },
    }: ReferenceImportSource.Response = yield call(
      reference.importSource,
      action.payload
    )

    if (data) {
      yield put(creator.importSuccess())
    } else if (meta) {
      errorMessage = meta
    }
  } catch (exception) {
    if (exception instanceof AxiosError) {
      errorMessage = exception.response?.data.meta 
    } else {
      errorMessage = Base.ErrorMessage.UNKNOWN
    }
  }finally {
    if (errorMessage) {
      yield put(creator.importError(errorMessage))
    }
  }
}

function* importWatcher() {
  yield takeEvery<Import>('reference.import' as Import['type'], importWorker)
}

export function* saga() {
  yield spawn(updateDataWatcher)
  yield spawn(updateAsorByDateWatcher)
  yield spawn(updateListFilterWatcher)
  yield spawn(createWatcher)
  yield spawn(updateWatcher)
  yield spawn(deleteWatcher)
  yield spawn(importWatcher)
}
