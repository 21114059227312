import { apiBase, apiBase2, apiBaseFiles } from 'api/base'

import { SUBPATH } from './organizations.const'
import {
  OrganizationFullInfo,
  OrganizationWithSubsidiariesList,
  OrganizationList,
  DetailedSearch,
  Directories,
  FiltersSearch,
  FilterRemove,
  FilterCreate,
} from './organizations.interface'
import { bodyDownload } from './organizations.model'

export const list = (params: OrganizationList.Query) =>
  apiBase.get<OrganizationList.Data>(`${SUBPATH}/is-exists`, { params })

export const organizationSubsidiaries = (
  params: OrganizationWithSubsidiariesList.Query
) =>
  apiBase.get<OrganizationWithSubsidiariesList.Data>(
    `${SUBPATH}/subsidiaries-info`,
    { params }
  )

export const organizationFullInfo = (params: OrganizationFullInfo.Query) =>
  apiBase.get<OrganizationFullInfo.Data>(`${SUBPATH}/organization-full-info`, {
    params,
  })

export const detailedSearch = (params: DetailedSearch.Query) =>
  apiBase.get<DetailedSearch.Data>(`${SUBPATH}/search-with-params`, {
    params,
  })

export const directories = () => apiBase.get<Directories.Data>(`${SUBPATH}/directory`)

export const filters = (params: FiltersSearch.Query) =>
  apiBase.get<FiltersSearch.Data>('filter/get', { params })

export const removeFilter = ({ id, userId }: FilterRemove.Query) =>
  apiBase.delete<FilterRemove.Data>(`filter/delete?userId=${userId}&id=${id}`)

export const createFilter = ({ user_id, name, comment, body }: FilterCreate.Query) =>
  apiBase.post<FilterCreate.Data>(`filter/save?user_id=${user_id}&name=${name}&comment=${comment} `, body)

export const downloadAll = (body: bodyDownload) =>
  apiBase2.post('upload/full-info', body)
