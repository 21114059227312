import jwt_decode from 'jwt-decode'
import moment from 'moment'
import { FC, ReactNode, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import { Header } from 'components'
import { Footer } from 'components/Footer'
import { Notification } from 'components/Notification'
import { ScrollButton } from 'components/ScrollButton'
import { useNotification } from 'hooks/useNotification'
import { auth } from 'modules'
import { ROUTE_PATH } from 'routes'

import styles from './styles.module.css'

type HomeLayoutProps = {
  children: ReactNode;
};

export interface AttributeCertificate {
  name: string;
  position?: string;
  unitName?: string;
  unp: string;
}

export interface User {
  email: string | null;
  emailVerified: boolean;
  enabled: boolean;
  fio: string;
  id: string;
  isNew: true | null;
  personalNumber: string;
  phone: string | null;
  username: string;
  work: AttributeCertificate | null;
}

export enum Roles {
  ADMINISTRATOR = 'administrator',
  APPROVER = 'approver',
  PERFORMER = 'performer',
}

export interface AccessToken {
  email: string;
  email_verified: boolean;
  exp: number;
  given_name: string;
  name: string;
  preferred_username: string;
  realm: string;
  realm_access: {
    roles: Roles[];
  };
}

class MetadataUserWork {
  unp: string = ''
  name: string = ''
  unitName: string = ''
  position: string = ''

  copy(copy: MetadataUserWork): MetadataUserWork {
    if (!copy) {
      copy = new MetadataUserWork()
    }
    this.unp = copy.unp
    this.name = copy.name
    this.unitName = copy.unitName
    this.position = copy.position
    return this
  }
}

class MetadataUser {
  id: string = ''
  username: string = ''
  enabled: boolean = false
  fio: string = ''
  personalNumber: string = ''
  email: string = ''
  emailVerified: boolean = false
  phone: string = ''
  work: MetadataUserWork = new MetadataUserWork()

  copy(copy: MetadataUser): MetadataUser {
    if (!copy) {
      copy = new MetadataUser()
    }
    this.id = copy.id
    this.username = copy.username
    this.enabled = copy.enabled
    this.fio = copy.fio
    this.personalNumber = copy.personalNumber
    this.email = copy.email
    this.emailVerified = copy.emailVerified
    this.phone = copy.phone
    this.work.copy(copy.work)
    return this
  }
}

export class MetadataLoginCallbackRs {
  user: MetadataUser = new MetadataUser()
  idToken: string = ''
  access_token: string = ''

  copy(copy: MetadataLoginCallbackRs): MetadataLoginCallbackRs {
    if (!copy) {
      copy = new MetadataLoginCallbackRs()
    }
    this.user.copy(copy.user)
    this.idToken = copy.idToken
    this.access_token = copy.access_token
    return this
  }

  reset() {
    this.copy(new MetadataLoginCallbackRs())
  }

  save() {
    localStorage.user_data = JSON.stringify(this)
  }

  restore() {
    this.copy(
      localStorage.user_data
        ? JSON.parse(localStorage.user_data)
        : new MetadataLoginCallbackRs()
    )
  }
}

export const metadataLoginCallbackRs: MetadataLoginCallbackRs =
  new MetadataLoginCallbackRs()

export const HomeLayout: FC<HomeLayoutProps> = ({ children }) => {
  const dispatch = useDispatch()
  const { openNotification, notificationData } = useNotification()
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code')
  const accessTokenQuery = searchParams.get('accessToken') || ''
  const refreshTokenQuery = searchParams.get('refreshToken') || ''
  const access_token = localStorage.getItem('access_token') || ''
  const refresh_token = localStorage.getItem('refresh_token') || ''

  const detailsForToken = {
    client_id: 'own-main-web-client',
    client_secret: window.env.REACT_APP_CLIENT_SECRET,
    code,
    grant_type: 'authorization_code',
    redirect_uri: window.env.REACT_APP_LOGOUT_REDIRECT
  }
  let formBody: string | string[] = []
  for (const property in detailsForToken) {
    const encodedKey = encodeURIComponent(property)
    // eslint-disable-next-line
    // @ts-ignore
    const encodedValue = encodeURIComponent(detailsForToken[property])
    formBody.push(encodedKey + '=' + encodedValue)
  }
  formBody = formBody.join('&')

  const detailsForRefresh = {
    client_id: 'own-main-web-client',
    client_secret: window.env.REACT_APP_CLIENT_SECRET,
    refresh_token,
    grant_type: 'refresh_token',
    redirect_uri: window.env.REACT_APP_LOGOUT_REDIRECT
  }
  let formBodyForRefresh: string | string[] = []
  for (const property in detailsForRefresh) {
    const encodedKey = encodeURIComponent(property)
    // eslint-disable-next-line
    // @ts-ignore
    const encodedValue = encodeURIComponent(detailsForRefresh[property])
    formBodyForRefresh.push(encodedKey + '=' + encodedValue)
  }
  formBodyForRefresh = formBodyForRefresh.join('&')

  const getUserProfile = async (accessToken: string) => {
    const response02: Response = await fetch(
      `${window.env.REACT_APP_PINCODE_URL}/api/admin/v1/own.agsr.by/profile`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    const json02: MetadataLoginCallbackRs = await response02.json()

    if (!response02.ok) {
      openNotification('Ошибка авторизации', 'error')
    } else {
      openNotification('Успешная авторизация', 'success')
      localStorage.setItem('user', JSON.stringify(json02))
      dispatch(auth.actions.loginSuccess(json02))
      window.location.href = ROUTE_PATH.home
    }
  }

  const getToken = async () => {
    const response01: Response = await fetch(
      `${window.env.REACT_APP_PINCODE_URL}/api/admin/v1/own.agsr.by/token`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
        },
        //@ts-ignore
        body: formBody,
      }
    )
    const json01: {
      access_token: string;
      refresh_token: string;
    } = await response01.json()

    if (!response01.ok) {
      openNotification('Ошибка при получении токена', 'error')
    } else {
      localStorage.setItem('access_token', json01.access_token)
      localStorage.setItem('refresh_token', json01.refresh_token)
      getUserProfile(json01.access_token)
    }
  }

  const refreshToken = async (refresh_token: string ) => {
    const response01: Response = await fetch(
      `${window.env.REACT_APP_PINCODE_URL}/api/admin/v1/own.agsr.by/token`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
        },
        //@ts-ignore
        body: formBodyForRefresh
      }
    )
    const json01: {
      access_token: string;
      refresh_token: string;
    } = await response01.json()
    localStorage.setItem('access_token', json01.access_token)
    localStorage.setItem('refresh_token', json01.refresh_token)
  }

  useEffect(() => {
    if (access_token && refresh_token) {
      const { exp }: AccessToken = jwt_decode(access_token)
      const isValidToken = moment(exp * 1000).isAfter(new Date())
      if (isValidToken) {
        dispatch(auth.actions.loginSuccess({ access_token, idToken: '' } ))
      } else {
        refreshToken(refresh_token)
      }
    }
  //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if(code) {
      getToken()
    }
    if(accessTokenQuery) {
      getUserProfile(accessTokenQuery)
      localStorage.setItem('access_token', accessTokenQuery)
      localStorage.setItem('refresh_token', refreshTokenQuery)
    }
  //eslint-disable-next-line
  },[code, accessTokenQuery])

  return (
    <>
      <Header />
      <main className={styles.main}>
        {children}
        <ScrollButton />
      </main>
      <Footer />
      <Notification {...notificationData} />
    </>
  )
}
