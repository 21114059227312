import {
  UpdateListFilter,
  ResetListFilter,
  GetList,
  GetListSuccess,
  GetListError,
  ResetList,
  Create,
  CreateSuccess,
  CreateError,
  ResetCreation,
  Update,
  UpdateSuccess,
  UpdateError,
  ResetUpdation,
  Delete,
  DeleteSuccess,
  DeleteError,
  ResetDeletion,
  Import,
  ImportSuccess,
  ImportError,
  ResetImportion,
  GetAsorByDate,
  GetAsorByDateSuccess,
  GetAsorByDateError,
  UpdateData,
  UpdateDataError,
  UpdateDataSuccess
} from './reference.action'

export const getAsorByDate = (
  payload: GetAsorByDate['payload']
): GetAsorByDate => ({
  type: 'reference.asor_by_date',
  payload
})

export const getAsorByDateError = (payload: GetAsorByDateError['payload']): GetAsorByDateError => ({
  type: 'reference.asor_by_date.error',
  payload,
})

export const getAsorByDateSuccess = (): GetAsorByDateSuccess => ({
  type: 'reference.asor_by_date.success',
  payload: undefined,
})

export const updateData = (
): UpdateData => ({
  type: 'reference.update_data',
  payload: undefined
})

export const updateDataError = (payload: UpdateDataError['payload']): UpdateDataError => ({
  type: 'reference.update_data.error',
  payload,
})

export const updateDataSuccess = (): UpdateDataSuccess => ({
  type: 'reference.update_data.success',
  payload: undefined,
})

export const updateListFilter = (
  payload: UpdateListFilter['payload']
): UpdateListFilter => ({
  type: 'reference.update_list_filter',
  payload,
})

export const resetFilter = (): ResetListFilter => ({
  type: 'referense.reset_list_filter',
  payload: undefined,
})

export const getList = (): GetList => ({
  type: 'reference.get_list',
  payload: undefined,
})

export const getListSuccess = (
  payload: GetListSuccess['payload']
): GetListSuccess => ({
  type: 'reference.get_list.success',
  payload,
})

export const getListError = (
  payload: GetListError['payload']
): GetListError => ({
  type: 'reference.get_list.error',
  payload,
})

export const resetList = (): ResetList => ({
  type: 'reference.reset_list',
  payload: undefined,
})

export const create = (payload: Create['payload']): Create => ({
  type: 'reference.create',
  payload,
})

export const createSuccess = (
  payload: CreateSuccess['payload']
): CreateSuccess => ({
  type: 'reference.create.success',
  payload,
})

export const createError = (payload: CreateError['payload']): CreateError => ({
  type: 'reference.create.error',
  payload,
})

export const resetCreation = (): ResetCreation => ({
  type: 'reference.reset_creation',
  payload: undefined,
})

export const update = (payload: Update['payload']): Update => ({
  type: 'reference.update',
  payload,
})

export const updateSuccess = (
  payload: UpdateSuccess['payload']
): UpdateSuccess => ({
  type: 'reference.update.success',
  payload,
})

export const updateError = (payload: UpdateError['payload']): UpdateError => ({
  type: 'reference.update.error',
  payload,
})

export const resetUpdation = (): ResetUpdation => ({
  type: 'reference.reset_updation',
  payload: undefined,
})

export const remove = (payload: Delete['payload']): Delete => ({
  type: 'reference.delete',
  payload,
})

export const removeSuccess = (): DeleteSuccess => ({
  type: 'reference.delete.success',
  payload: undefined,
})

export const removeError = (payload: DeleteError['payload']): DeleteError => ({
  type: 'reference.delete.error',
  payload,
})

export const resetDeletion = (): ResetDeletion => ({
  type: 'reference.reset_deletion',
  payload: undefined,
})

export const importSrc = (payload: Import['payload']): Import => ({
  type: 'reference.import',
  payload,
})

export const importSuccess = (): ImportSuccess => ({
  type: 'reference.import.success',
  payload: undefined,
})

export const importError = (payload: ImportError['payload']): ImportError => ({
  type: 'reference.import.error',
  payload,
})

export const resetImportion = (): ResetImportion => ({
  type: 'reference.reset_importion',
  payload: undefined,
})
